<template>
  <div class="rating">
    <ul class="rating__list">
      <li :key="star" v-for="star in maxStars" :class="{ 'active': star <= stars }" @click="rate(star)" class="rating__star">
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 0L11.4334 5.65067L17.5595 6.21885L12.9374 10.2793L14.2901 16.2812L9 13.14L3.70993 16.2812L5.06263 10.2793L0.440492 6.21885L6.56657 5.65067L9 0Z" fill="#C4C4C4"/>
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    grade: {
      type: Number,
      required: true
    },
    maxStars: {
      type: Number,
      default: 5
    },
    hasCounter: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      stars: this.grade
    }
  },
  created() {
    Math.round(this.grade)
  },
  methods: {
    rate(star) {
     if(!this.readonly){
       if (
           typeof star === 'number' &&
           star <= this.maxStars &&
           star >= 0
       )
         this.stars = this.stars === star ? star - 1 : star
         this.$emit('getStars', this.stars)
     }
    }
  }
}
</script>

<style scoped lang="scss">

ul li {
  list-style: none;
}
.rating {
  &__list {
    display: flex;
    align-items: center;
  }
  &__star {
    &:hover, &.active {
      svg{
        path{
          fill:#FFD002;
        }
      }
    }
  }
}

</style>
